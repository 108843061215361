<template>
  <svg
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="46" height="46" rx="8" fill="#6886FF" />
    <path
      d="M7.45898 31.111L39.4902 31.111C39.9999 31.111 40.4888 31.3134 40.8492 31.6739C41.2096 32.0343 41.4121 32.5231 41.4121 33.0328C41.4121 33.5425 41.2096 34.0314 40.8492 34.3918C40.4888 34.7522 39.9999 34.9547 39.4902 34.9547H7.45898C7.2066 34.9547 6.95669 34.905 6.72351 34.8084C6.49034 34.7118 6.27848 34.5703 6.10001 34.3918C5.92155 34.2133 5.77999 34.0015 5.6834 33.7683C5.58682 33.5351 5.53711 33.2852 5.53711 33.0328C5.53711 32.7805 5.58682 32.5305 5.6834 32.2974C5.77999 32.0642 5.92155 31.8523 6.10001 31.6739C6.27848 31.4954 6.49034 31.3538 6.72351 31.2573C6.95669 31.1607 7.2066 31.111 7.45898 31.111Z"
      fill="#FDDE2E"
    />
    <path
      d="M10.0469 16.7993L13.5063 12.4174C13.588 12.3181 13.6907 12.2381 13.807 12.1832C13.9233 12.1283 14.0503 12.0999 14.1789 12.0999C14.3075 12.0999 14.4345 12.1283 14.5508 12.1832C14.6671 12.2381 14.7698 12.3181 14.8516 12.4174C15.3897 13.1093 16.1328 14.0958 16.8631 14.9799C17.3206 15.5603 17.9048 16.0283 18.5711 16.3481C19.2373 16.6679 19.9679 16.831 20.7069 16.8249H26.37C27.1087 16.8291 27.8386 16.6652 28.5046 16.3455C29.1705 16.0258 29.755 15.5589 30.2138 14.9799L32.2253 12.4174C32.3058 12.3271 32.4045 12.2549 32.5149 12.2054C32.6254 12.1559 32.745 12.1304 32.8659 12.1304C32.9869 12.1304 33.1065 12.1559 33.2169 12.2054C33.3273 12.2549 33.426 12.3271 33.5066 12.4174L36.9788 16.7993L31.1875 26.9596L16.4916 27.1774L10.0469 16.7993Z"
      fill="#FDDE2E"
    />
    <path
      d="M5.80687 14.6211C5.78953 14.4451 5.82698 14.268 5.91412 14.1141C6.00126 13.9601 6.13383 13.8369 6.29369 13.7611C6.45355 13.6854 6.6329 13.6609 6.80721 13.691C6.98152 13.7211 7.14228 13.8043 7.2675 13.9293L13.0972 19.9383C13.2826 20.1321 13.5053 20.2863 13.7519 20.3917C13.9986 20.4971 14.264 20.5514 14.5322 20.5514C14.8004 20.5514 15.0658 20.4971 15.3124 20.3917C15.5591 20.2863 15.7818 20.1321 15.9672 19.9383L22.8859 12.8018C22.9675 12.7132 23.0666 12.6426 23.1768 12.5943C23.2871 12.5459 23.4062 12.521 23.5266 12.521C23.6469 12.521 23.766 12.5459 23.8763 12.5943C23.9865 12.6426 24.0856 12.7132 24.1672 12.8018L31.0859 19.9383C31.2713 20.1321 31.4941 20.2863 31.7407 20.3917C31.9873 20.4971 32.2527 20.5514 32.5209 20.5514C32.7891 20.5514 33.0545 20.4971 33.3012 20.3917C33.5478 20.2863 33.7705 20.1321 33.9559 19.9383L39.7856 13.9293C39.9095 13.8025 40.0697 13.7172 40.244 13.685C40.4184 13.6529 40.5984 13.6756 40.7594 13.7499C40.92 13.8277 41.0528 13.9531 41.1399 14.1089C41.227 14.2647 41.2641 14.4435 41.2463 14.6211L39.5422 31.1108L7.51094 31.1108L5.80687 14.6211Z"
      fill="#FCE87B"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.1395 25.1272C24.0574 25.2142 23.958 25.2831 23.8477 25.3295C23.7373 25.3758 23.6185 25.3986 23.4989 25.3963C23.3775 25.3973 23.2572 25.374 23.145 25.3278C23.0328 25.2816 22.9309 25.2134 22.8455 25.1272L20.2189 22.5647C20.0499 22.3943 19.9551 22.1641 19.9551 21.9241C19.9551 21.6841 20.0499 21.4539 20.2189 21.2835L22.8455 18.6569C22.9299 18.5684 23.0312 18.4979 23.1435 18.4494C23.2558 18.401 23.3766 18.3757 23.4989 18.375C23.6185 18.3728 23.7373 18.3955 23.8477 18.4419C23.958 18.4882 24.0574 18.5571 24.1395 18.6441L26.7789 21.2707C26.9479 21.441 27.0427 21.6713 27.0427 21.9113C27.0427 22.1513 26.9479 22.3815 26.7789 22.5519L24.1395 25.1272Z"
      fill="#5375FD"
    />
    <path
      d="M11.4682 27.5873C11.4494 27.3946 11.373 27.2121 11.2487 27.0636C11.1245 26.9151 10.9584 26.8076 10.772 26.7552C10.5857 26.7027 10.3878 26.7078 10.2044 26.7697C10.021 26.8316 9.86056 26.9475 9.74411 27.1021C9.62765 27.2567 9.56062 27.4429 9.55178 27.6363C9.54295 27.8297 9.59272 28.0212 9.69459 28.1858C9.79646 28.3505 9.94567 28.4805 10.1227 28.5588C10.2997 28.6372 10.4962 28.6603 10.6866 28.6251C10.8121 28.6078 10.933 28.5658 11.0422 28.5017C11.1515 28.4375 11.2469 28.3523 11.3232 28.2511C11.3994 28.1499 11.4548 28.0346 11.4863 27.9119C11.5178 27.7892 11.5247 27.6614 11.5066 27.536"
      fill="#FDDE2E"
    />
    <path
      d="M10.9706 24.0641C10.9436 23.8762 10.8615 23.7006 10.7347 23.5593C10.6079 23.4181 10.4421 23.3175 10.2583 23.2704C10.0745 23.2232 9.88076 23.2316 9.70165 23.2943C9.52254 23.3571 9.36603 23.4715 9.25187 23.6232C9.1377 23.7748 9.071 23.9568 9.06016 24.1463C9.04933 24.3358 9.09485 24.5243 9.19098 24.6879C9.28712 24.8516 9.42956 24.9831 9.60035 25.0659C9.77113 25.1487 9.96261 25.179 10.1506 25.1531C10.2762 25.1358 10.397 25.0939 10.5063 25.0297C10.6155 24.9655 10.711 24.8804 10.7872 24.7792C10.8634 24.678 10.9189 24.5627 10.9503 24.4399C10.9818 24.3172 10.9887 24.1895 10.9706 24.0641Z"
      fill="#FDDE2E"
    />
    <path
      d="M10.4706 20.5276C10.4447 20.2712 10.3182 20.0356 10.1189 19.8722C9.91953 19.7089 9.6636 19.6313 9.40713 19.6563C9.15596 19.6943 8.93004 19.8302 8.77878 20.0343C8.62752 20.2384 8.56322 20.494 8.59994 20.7454C8.61397 20.8711 8.65335 20.9927 8.71569 21.1027C8.77803 21.2127 8.86204 21.309 8.96265 21.3857C9.06325 21.4623 9.17835 21.5178 9.301 21.5486C9.42364 21.5795 9.55128 21.5852 9.67619 21.5654C9.80171 21.5481 9.92257 21.5062 10.0318 21.442C10.1411 21.3778 10.2365 21.2927 10.3128 21.1915C10.389 21.0902 10.4444 20.975 10.4759 20.8522C10.5074 20.7295 10.5143 20.6018 10.4962 20.4763L10.4706 20.5276Z"
      fill="#FDDE2E"
    />
    <path
      d="M35.5326 27.5874C35.5514 27.3947 35.6278 27.2122 35.7521 27.0637C35.8763 26.9152 36.0424 26.8077 36.2288 26.7553C36.4151 26.7029 36.613 26.7079 36.7964 26.7698C36.9798 26.8317 37.1402 26.9476 37.2567 27.1022C37.3731 27.2569 37.4402 27.4431 37.449 27.6364C37.4579 27.8298 37.4081 28.0214 37.3062 28.186C37.2043 28.3506 37.0551 28.4806 36.8781 28.559C36.7011 28.6373 36.5046 28.6604 36.3142 28.6252C36.1887 28.6079 36.0678 28.566 35.9586 28.5018C35.8493 28.4376 35.7539 28.3525 35.6776 28.2512C35.6014 28.15 35.546 28.0347 35.5145 27.912C35.483 27.7893 35.4761 27.6615 35.4942 27.5361"
      fill="#FDDE2E"
    />
    <path
      d="M36.0313 24.0641C36.0584 23.8762 36.1405 23.7006 36.2673 23.5593C36.394 23.4181 36.5598 23.3175 36.7437 23.2704C36.9275 23.2232 37.1212 23.2316 37.3003 23.2943C37.4794 23.3571 37.6359 23.4715 37.7501 23.6232C37.8642 23.7748 37.931 23.9568 37.9418 24.1463C37.9526 24.3358 37.9071 24.5243 37.811 24.6879C37.7148 24.8515 37.5724 24.9831 37.4016 25.0659C37.2308 25.1487 37.0393 25.179 36.8513 25.1531C36.7258 25.1358 36.6049 25.0939 36.4957 25.0297C36.3864 24.9655 36.291 24.8804 36.2148 24.7792C36.1385 24.678 36.0831 24.5627 36.0516 24.4399C36.0201 24.3172 36.0132 24.1895 36.0313 24.0641Z"
      fill="#FDDE2E"
    />
    <path
      d="M36.5318 20.5277C36.5395 20.395 36.5746 20.2653 36.635 20.1469C36.6953 20.0285 36.7796 19.9239 36.8825 19.8397C36.9853 19.7555 37.1045 19.6936 37.2325 19.6578C37.3605 19.622 37.4946 19.6132 37.6262 19.6318C37.7578 19.6505 37.884 19.6963 37.9971 19.7663C38.1101 19.8362 38.2073 19.9289 38.2827 20.0384C38.3581 20.1478 38.4099 20.2718 38.4349 20.4023C38.4599 20.5328 38.4576 20.6672 38.4281 20.7967C38.4204 20.9294 38.3853 21.0591 38.3249 21.1775C38.2646 21.2959 38.1803 21.4006 38.0775 21.4847C37.9746 21.5689 37.8554 21.6309 37.7274 21.6667C37.5994 21.7024 37.4654 21.7113 37.3338 21.6926C37.2022 21.6739 37.0759 21.6281 36.9629 21.5582C36.8499 21.4882 36.7526 21.3955 36.6772 21.2861C36.6019 21.1766 36.55 21.0527 36.525 20.9221C36.5 20.7916 36.5023 20.6573 36.5318 20.5277Z"
      fill="#FDDE2E"
    />
  </svg>
</template>
